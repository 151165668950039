.contact {

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    align-items: center;
  }

  &__form-field {
    width: 34rem;
    display: flex;
    margin-bottom: 2rem;

  }

  &__label {
    width: 5rem;
    margin-right: 2rem;
    font-family: $font-primary;
    font-size: 1.4rem;
    letter-spacing: .82px;
  }

  &__input-field {
    width: 29rem;
    background-color: rgba(0,0,0,0);
    padding-bottom: 5px;
    border: none;
    border-bottom: 2px solid rgba(255,255,255,0.7);
    color: rgba(255,255,255,0.7);
    transform: translateY(4px);
    font-size: 1.4rem;
    font-family: $font-primary;
    transition: 0.2 all ease-in;

    &:-webkit-autofill{
      background-color: rgba(0,0,0,0) !important;
    }


    &:focus{
      outline: none;

    }

  }

  &__form-button {
    margin-top: 6rem;
    margin-left: 25rem;
    cursor: pointer;
    background-color: rgba(0,0,0,0)
  }
}
