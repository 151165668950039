.header {
  padding: 4rem 15rem;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;

  @include respond(tabPort) {
    padding: 3rem 5rem;
  }

  @include respond(phone) {
    padding-top: 4rem;
    padding: 1rem;
    // margin-top: 2rem;  
  }


  &__navigation {
    display: flex;
    align-items: center;
  }

  &__navigation-link {
    &,
    &:link,
    &:visited {
      text-transform: capitalize;
      margin: 0 1.5rem;
      font-family: $font-titles;
      font-size: 1.4rem;
      letter-spacing: 0.9px;
      text-decoration: none;
      color: $color-white;

      @include respond(phone) {
        margin: 0 0.8rem;
      }
    }

    // don't do the contact button
    &:not(:last-child) {
      &,
      &:link,
      &:visited {
        position: relative;
        font-size: 1.4rem;
        letter-spacing: 0.9px;
      }
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5.2rem;
        height: 5.2rem;
        border: 1px solid rgba(255, 255, 255, 0.16);
        border-radius: 50%;
        content: '';
        opacity: 0;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        -moz-transition: -moz-transform 0.3s, opacity 0.3s;
        transition: transform 0.3s, opacity 0.3s;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(0.2);
        -moz-transform: translateX(-50%) translateY(-50%) scale(0.2);
        transform: translateX(-50%) translateY(-50%) scale(0.2);
      }

      &::after {
        width: 4.7rem;
        height: 4.7rem;
        border-width: 3px;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8);
        -moz-transform: translateX(-50%) translateY(-50%) scale(0.8);
        transform: translateX(-50%) translateY(-50%) scale(0.8);
      }

      &:hover::before,
      &:hover::after,
      &:focus::before,
      &:focus::after {
        opacity: 1;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
        -moz-transform: translateX(-50%) translateY(-50%) scale(1);
        transform: translateX(-50%) translateY(-50%) scale(1);
      }

    }
    }


}

.logo {
  &,
  &:link,
  &:visited {
    font-family: $font-titles;
    font-size: 2.4rem;
    text-decoration: none;
    color: $color-white;

    @include respond(phone) {
      margin-left: 1rem;
    }
  }
}

.contact-button {
  text-transform: capitalize;
  font-family: $font-titles;
  font-size: 1.4rem;
  letter-spacing: 0.9px;
  text-decoration: none;
  color: $color-white;
  width: 10.8rem;
  height: 3.3rem;
  padding: .8rem 2.8rem;
  border-radius: 50px;
  border: 1px solid $colour-orange;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in;

  &:hover {
    background-color: $colour-orange;

  }
}
