
.three-wise-men {
  width: 50rem;
  display: flex;

  @include respond(tabPort) {
    width: 100%
  }
}

.a-wise-man {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @include respond(tabPort) {
    width: 30%;
  }
}

.homepage {
  &-img {
    height: 11.8rem;
    animation: homepage-img-spin infinite 20s linear;
  }

  &__image-descriptor {
    font-family: $font-titles;
    font-size: 1.4rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-top: 1.8rem;
  }
}


.main-container {
  margin-top: 27rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include respond(phone) {
    margin-top: 12rem;
  }

  &__wrapper{
    width: 105.6rem;

    @include respond(tabPort) {
      width: auto;
      max-width: 105.6rem;
    }
  }

  &__line {
    transform: translate(-2rem, 2rem);
    width: 7rem;
    height: 1px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;

    @include respond(tabLand) {
      display: none;
    }

    &::after {
      transform: translate(50%, -50%);
      content: '';
      background-color: #fff;
      height: 6px;
      width: 6px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }

  &__page-title {
    font-family: $font-titles;
    font-size: 2.4rem;
    text-transform: uppercase;

    @include respond(phone) {
      margin-left: 3rem;
    }
  }

  &__content {
    display: flex;
    margin-top: 2.2rem;
    flex-wrap: wrap;

    @include respond(tabPort) {
      justify-content: center;
    }
  }

  &__text {
    max-width: 38rem;
    font-family: $font-primary;
    color: $color-white;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 0.8px;

    @media (max-width: 40.625em) {
      margin-bottom: 3rem;
    }
  }
}

.body-contact-button {
  margin-top: 3rem;
  width: 100%;
  text-transform: capitalize;
  text-decoration: none;

  font-family: $font-titles;
  font-size: 1.4rem;
  letter-spacing: 0.9px;

  @include respond(phone) {
    margin-left: 3rem;
    margin-bottom: 2rem;
  }
}

.client-logos {
  margin: 1rem;
}


@keyframes homepage-img-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
