*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  // width: 100%;
  // height: 100%;

}

a {
  text-decoration: none;
}

html {
  font-size: 62.5%;


  @include respond(tabLand) { // width < 1200
    font-size: 56.25%; // 9px
  }

  @include respond(tabPort) { // width < 900
    font-size: 50%; // 8px
  }

  // @include respond(bigDesktop) {
  //   font-size: 75%; // 12px
  // }


}

body {
  font-family: $font-primary, sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: $color-white;
  box-sizing: border-box;
  overflow-x: hidden;  

  @include respond(tabPort) { // width < 900
    padding: 0;
  }
}

.background {
  background-image: url('/assets/Stars2.jpg');
  background-size: cover;
  height: 100vh;

  @include respond(tabPort) {
    background-image: url('/assets/Stars2-mobile.jpg');
  }

}

.site-title {
    &,
    &:link,
    &:visited {
      color: $color-gray-dark;
      font-size: 2rem;
      text-decoration: none;
    }
}

.container {
  padding: 4.5rem 0 0 15rem;
}
