.client-logos {
  // margin: 1rem;
  padding: 0 3rem;
  width: 22.5%;
  height: 22.5%;

  @include respond(phone) {
    width: 33%;
    height: 33%;
  }
}

.clients-container {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
